/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'application.css';
import 'admin-console.css';

import "@stimulus/polyfills"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import "trix/dist/trix.css";
// import { Trix } from "trix"

// {lang} = Trix.config

// Trix.config.toolbar.getDefaultHTML = function() {
//   return ```
//     <div class="trix-button-row">
//       <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
//         <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="#{lang.bold}" tabindex="-1">#{lang.bold}</button>
//         <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="#{lang.italic}" tabindex="-1">#{lang.italic}</button>
//         <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="#{lang.strike}" tabindex="-1">#{lang.strike}</button>
//         <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="#{lang.link}" tabindex="-1">#{lang.link}</button>
//       </span>
//     </div>
//   ```;
// }

var Trix  = require("trix");
console.log("Config", Trix.config);

// Trix.config.textAttributes.greenHighlight = { tagName: "span", inheritable: true }

Trix.config.textAttributes.greenHighlight = {
    style: { color: 'rgb(13, 135, 25)' },
    parser: function(element) {
      var value;
      while (element && element.tagName !== "TRIX-EDITOR") {
        if (value = element.style.color) {
          return value === 'rgb(13, 135, 25)';
        }
        element = element.parentElement;
      }
      // var style = window.getComputedStyle(element)
      // return style === "green"
    },
    inheritable: true
}


addEventListener("trix-initialize", function(event) {
  var buttonHTML, buttonGroup
  buttonHTML  = '<button type="button" class="trix-button" data-trix-attribute="greenHighlight">Grün</button>'
	buttonGroup = event.target.toolbarElement.querySelector(".trix-button-group.trix-button-group--text-tools")
  buttonGroup.insertAdjacentHTML("beforeend", buttonHTML)
})


const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

require("@rails/actiontext")
